import {createFeatureSelector, createSelector, props} from '@ngrx/store';
import {createReducer, on} from '@ngrx/store';
import {Nullable} from '../../common/types';
import {User} from '../../models/User';
import * as userActions from './user.actions';
import * as loggRocket from 'logrocket'

export interface UserState {
    loginAttempted: boolean;
    user: Nullable<User>;
}

export const initialState = {
    loginAttempted: false,
    user: null,
};

export const userReducer = createReducer<UserState>(
    initialState,
    on(userActions.loginAttempted, state => ({
        ...state,
        loginAttempted: true,
    })),
    on(userActions.updateUser, (state, action) => {
        loggRocket.identify(action.user.id.toString(), {
            name: action.user.name,
            email: action.user.email,
        });
        return {
            ...state,
            user: action.user,
        }
    })
);

export const userState = createFeatureSelector<UserState>('user');

export const loggedInUser = createSelector(userState, (state: UserState): Nullable<User> => state.user)
export const isLoggedIn = createSelector(
    loggedInUser,
    (user: Nullable<User>): boolean => user != null && user.email != null
);
